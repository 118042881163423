import React from 'react'
import styled from 'styled-components'
import {
  Box,
  Container,
  Flex,
  Text,
  theme,
  Icon,
  Link as A
} from '@hackclub/design-system'
import { Fade } from 'react-reveal'
import { Headline, Lead } from 'components/Content'
import Sheet from 'components/Sheet'
import ContactForm from 'components/Contact/Form'

const Grid = styled(Container).attrs({ maxWidth: 48 })`
  display: grid;
  grid-gap: ${theme.space[3]}px;
  align-items: start;
  ${theme.mediaQueries.md} {
    grid-gap: ${theme.space[5]}px;
    grid-template-columns: 3fr 2fr;
  }
`

const Timeline = styled(Flex).attrs({
  flexDirection: 'column'
})`
  line-height: 1.125;
  position: relative;
  &:before {
    content: '';
    background-image: linear-gradient(
      to bottom,
      ${theme.colors.dark} 0%,
      ${theme.colors.slate} 15%,
      ${theme.colors.slate} 85%,
      ${theme.colors.dark} 100%
    );
    height: 100%;
    width: 3px;
    margin-left: 4px;
    position: absolute;
  }
`
const TimelineStep = styled(Flex).attrs({
  align: 'center',
  pt: props => (props.first ? 0 : [2, 3])
})`
  line-height: 1.125;
`
const Circle = styled(Box).attrs({ p: 1, bg: 'primary', color: 'white' })`
  border-radius: ${theme.pill};
  display: inline-block;
  line-height: 0;
`

const Services = styled(Flex)`
  a {
    line-height: 0;
    color: #4166f5;
    margin-top: 10px;
  }
  svg {
    fill: currentColor;
    width: 42px;
    height: 42px;
    transition: 0.5s;
    &:hover {
      transform: scale(1.4);
    }
  }
  ${theme.mediaQueries.md} {
    max-width: 14rem;
  }
`
Services.defaultProps = {
  align: 'center',
  mb: 3,
  wrap: true
}

const Service = ({ href, icon, ...props }) => (
  <A
    target="_blank"
    rel="noopener"
    href={href}
    mr={2}
    mb={2}
    color="muted"
    aria-label={`Coding Sastra on ${icon}`}
    children={<Icon glyph={icon} />}
    {...props}
  />
)

Timeline.Step = ({ name, duration, first = false }) => (
  <Fade top>
    <TimelineStep first={first}>
      <Circle mr={3} />
      <Box align="left">
        <Text color="muted" fontSize={0} caps children={duration} />
        <Text color="violet.5" fontSize={2} children={name} />
      </Box>
    </TimelineStep>
  </Fade>
)

const Contact = () => {
  return (
    <Box.section bg="white" py={[5, 6, 7]} id="apply">
      <Container maxWidth={48} px={3} align="center">
        <Headline color="black" mb={2}>
          Contact
        </Headline>
        <Lead maxWidth={32} color="fuschia.5">
          Contact us for training requirements
        </Lead>
      </Container>
      <Grid mt={[4, 5]} mb={[3, 4]} px={3}>
        <Fade bottom>
          <Sheet bg="snow" color="black" maxWidth={32} p={[3, 4, 5]}>
            <ContactForm />
          </Sheet>
        </Fade>
        <div>
          <Timeline>
            <Timeline.Step
              icon="send"
              name="contact@codingsastra.com"
              duration="Email"
              first
            />
            <Timeline.Step name="+91 9951013134" duration="Phone" />
            <Timeline.Step name="+91 9951013134" duration="Whatsapp" />
            <Timeline.Step name="Hyderabad" duration="Address" />
          </Timeline>
          <Box>
            <Lead maxWidth={36} color="slate" fontSize={2} mt={4}>
              <br /> HIG-129, Pramani Plaza, 5th Floor 
              <br /> Phase 1&2 Kukatpally
              <br /> KPHB, Hyderabad, TS 500082.
            </Lead>
            <Fade right>
              <Services>
                <Service
                  href="https://www.facebook.com/codingsastra/"
                  icon="facebook"
                  style={{ color: '#3b5998' }}
                />
                <Service
                  href="https://twitter.com/codingsastra"
                  icon="twitter"
                  style={{ color: '#00acee' }}
                />
                <Service
                  href="https://www.youtube.com/channel/UCI8ICu95T0cIHq0ymzufT8A"
                  icon="youtube"
                  style={{ color: '#c4302b' }}
                />
                <Service
                  href="mailto:contact@codingsastra.com"
                  icon="email-fill"
                />
              </Services>
            </Fade>
          </Box>
        </div>
      </Grid>
      <Lead maxWidth={36} color="slate" align="center" fontSize={2}>
        Your email address will not be published. Required fields are marked.
      </Lead>
    </Box.section>
  )
}

export default Contact
