import React from 'react'
import { theme } from '@hackclub/design-system'
import Layout from 'components/Layout'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import Contact from '../components/Contact/Contact'
import ContactLanding from 'components/Contact/Landing'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'

const styles = `
  body {
    width: 100%;
    max-width: 100vw;
    background: ${theme.colors.dark};
  }
  ::selection {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    text-shadow: none;
  }
`

const title = 'Contact Us- Coding Sastra'

export default ({ data }) => (
  <Layout
    title={title}
    desc={data.site.siteMetadata.description}
    keywords={data.site.siteMetadata.keywords}
    path="/contact/"
  >
    <style children={styles} />
    <ContactLanding />
    <Nav />
    <Social />
    <Contact />
    <Footer />
  </Layout>
)

export const query = graphql`
  query contactMetaData {
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
