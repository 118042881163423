import React from 'react'
import styled from 'styled-components'
import bgimg from '../../assets/imgs/contactbg.jpg'
import { Container, Flex, Heading } from '@hackclub/design-system'
import Fade from 'react-reveal/Fade'

const Slide = styled(Flex).attrs({
  flexDirection: 'column',
  justify: 'end',
  bg: 'snow',
  width: '100vw'
})`
  background: url(${bgimg});
  box-shadow: inset 0 0 4rem 4rem rgba(0, 0, 0, 0.5);
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 60vh;
  position: relative;
  h1 {
    line-height: 1.125;
    letter-spacing: -0.02em;
    text-shadow: 0 0 16px rgba(0, 0, 0, 1);
  }
  p {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  }
`

const Vignette = styled.div`
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.25) 25%,
    rgba(0, 0, 0, 0.625) 50%,
    rgba(0, 0, 0, 0.75) 100%
  );
  height: 50vh;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
`

const ContactLanding = () => {
  return (
    <Slide>
      <Vignette />
      <Container
        pt={[6, 7, 8]}
        pb={[4, 5]}
        px={3}
        align="center"
        color="white"
        mt="auto"
        maxWidth={75}
        style={{ zIndex: 100 }}
      >
        <Fade bottom>
          <Heading.h1 fontSize={[6, 7, 8, 9]}>Contact Us</Heading.h1>
        </Fade>
      </Container>
    </Slide>
  )
}
export default ContactLanding
